@import url("https://storage.googleapis.com/demos.nplan.io/assets/FontAwesome/css/all.min.css");

@font-face {
  font-family: "geomanist";
  src: url("./assets/fonts/geomanist-medium-webfont.eot");
  src: url("./assets/fonts/geomanist-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/geomanist-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/geomanist-medium-webfont.woff") format("woff"),
    url("./assets/fonts/geomanist-medium-webfont.ttf") format("truetype"),
    url("./assets/fonts/geomanist-medium-webfont.svg#geomanistregular")
      format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "geomanist";
  src: url("./assets/fonts/geomanist-book-webfont.eot");
  src: url("./assets/fonts/geomanist-book-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/geomanist-book-webfont.woff2") format("woff2"),
    url("./assets/fonts/geomanist-book-webfont.woff") format("woff"),
    url("./assets/fonts/geomanist-book-webfont.ttf") format("truetype"),
    url("./assets/fonts/geomanist-book-webfont.svg#geomanistregular")
      format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "geomanist";
  src: url("./assets/fonts/geomanist-regular-webfont.eot");
  src: url("./assets/fonts/geomanist-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/geomanist-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/geomanist-regular-webfont.woff") format("woff"),
    url("./assets/fonts/geomanist-regular-webfont.ttf") format("truetype"),
    url("./assets/fonts/geomanist-regular-webfont.svg#geomanistregular")
      format("svg");
  font-weight: 400;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  width: 100vw;
}

/* "See https://github.com/facebook/create-react-app/issues/11773", */
iframe {
  pointer-events: none;
}

a {
  text-decoration: none;
}

body {
  font-family: "Geomanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
