@import url(https://storage.googleapis.com/demos.nplan.io/assets/FontAwesome/css/all.min.css);
@font-face {
  font-family: "geomanist";
  src: url(/static/media/geomanist-medium-webfont.a33a0c84.eot);
  src: url(/static/media/geomanist-medium-webfont.a33a0c84.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/geomanist-medium-webfont.2e0c587d.woff2) format("woff2"),
    url(/static/media/geomanist-medium-webfont.c994aab3.woff) format("woff"),
    url(/static/media/geomanist-medium-webfont.d9b98e15.ttf) format("truetype"),
    url(/static/media/geomanist-medium-webfont.263441fa.svg#geomanistregular)
      format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "geomanist";
  src: url(/static/media/geomanist-book-webfont.eeabfbd1.eot);
  src: url(/static/media/geomanist-book-webfont.eeabfbd1.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/geomanist-book-webfont.70b661c6.woff2) format("woff2"),
    url(/static/media/geomanist-book-webfont.375b6b0c.woff) format("woff"),
    url(/static/media/geomanist-book-webfont.69256277.ttf) format("truetype"),
    url(/static/media/geomanist-book-webfont.3493baa8.svg#geomanistregular)
      format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "geomanist";
  src: url(/static/media/geomanist-regular-webfont.e7124339.eot);
  src: url(/static/media/geomanist-regular-webfont.e7124339.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/geomanist-regular-webfont.260f60c2.woff2) format("woff2"),
    url(/static/media/geomanist-regular-webfont.b749c3d4.woff) format("woff"),
    url(/static/media/geomanist-regular-webfont.dfc15950.ttf) format("truetype"),
    url(/static/media/geomanist-regular-webfont.7d6e16fe.svg#geomanistregular)
      format("svg");
  font-weight: 400;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  width: 100vw;
}

/* "See https://github.com/facebook/create-react-app/issues/11773", */
iframe {
  pointer-events: none;
}

a {
  text-decoration: none;
}

body {
  font-family: "Geomanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

:root {
  --rdp-cell-size: 40px;
  --rdp-accent-color: var(--chakra-colors-blue-500);
  --rdp-background-color: var(--chakra-colors-blue-100);
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}
.rdp-day_selected:not([disabled]),
.rdp-day_selected:focus:not([disabled]),
.rdp-day_selected:active:not([disabled]),
.rdp-day_selected:hover:not([disabled]) {
  color: white;
}

